export const galleryPics = [
    {
        src : '../images/gallery/8.jpg',
        title: 'Entrance Arrival',         
    },
    {
        src : '../images/gallery/1.jpg', 
        title : 'Flexible Office Space - CAT A'
        
    },
    {
        src : '../images/gallery/2.jpg',
        title: 'Flexible Office Space - CAT A'
    },
    {
        src : '../images/gallery/3.jpg',
        title: 'Flexible Office Space - CAT A'
    },
    {
        src : '../images/gallery/4.jpg',
        title : 'Modern Reception',
    },
    {
        src : '../images/gallery/6.jpg',
        title: 'Contemporary Warehouse Workspace',
        
    },
    {
        src : '../images/gallery/5.jpg',
        title: 'Roof Terrace'
        
    },
    {
        src : '../images/gallery/7.jpg',
        title: 'Ground Floor Courtyard',
    },
   
]